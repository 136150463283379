import React from "react";
import { Link } from "react-router-dom";

const ButtonComponent = React.memo(({ data, action, toggleSidebar }) => {

    const main = process.env.REACT_APP_MAIN;
    const { label, type, to } = data;

    if (type === "Link") {
        return <Link onClick={() => action("Link")} to={main + to}>{label}</Link>;
    } else {
        return <button className="menu-btn-scroll" onClick={() => action(to)}>{label}</button>;
    }
});

export default ButtonComponent;
